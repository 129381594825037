<template>
  <div class="warp">
    <div class="header">
      <div class="title">{{ section.sectionName || '' }}</div>
      <router-link
        class="more"
        :to="{
          path: '/movies/more/new',
          query: {
            title: section.sectionName || '',
            id: section.sectionID || '',
          },
        }"
      >
        更多
      </router-link>
    </div>
    <div class="content">
      <!--  大图-->
      <div class="one_big_img" @click="goPlay(section.videoInfo[0].id)">
        <div class="big_cover">
          <ImgDecypt :src="section.videoInfo[0].cover" />
          <TipIcon :videoInfo="section.videoInfo[0]" />
          <div class="video_info">
            <div class="watch">
              <svg-icon icon-class="tip_play" />
              <span>{{ section.videoInfo[0].playCount | watchCount }}</span>
            </div>
            <div class="time">
              <span>{{ section.videoInfo[0].playTime | videotime }}</span>
            </div>
          </div>
        </div>
        <p class="video_desc ellipsis">{{ section.videoInfo[0].title }}</p>
      </div>
      <!--  小图-->
      <div class="four_small-img">
        <div class="small_item" v-for="videoInfo in section.videoInfo.slice(1)" :key="videoInfo.id" @click="goPlay(videoInfo.id)">
          <div class="small_img">
            <ImgDecypt :src="videoInfo.cover" />
            <TipIcon :videoInfo="videoInfo" />
            <div class="video_info">
              <div class="watch">
                <svg-icon icon-class="tip_play" />
                <span>{{ videoInfo.playCount | watchCount }}次</span>
              </div>
              <div class="time">
                <span>{{ videoInfo.playTime | videotime }}</span>
              </div>
            </div>
          </div>
          <p class="videoTitle ellipsis">{{ videoInfo.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImgDecypt from '@/components/ImgDecypt';
import TipIcon from '@/components/TipIcon';
export default {
  props: {
    section: {
      required: true,
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    ImgDecypt,
    TipIcon,
  },
  methods: {
    goPlay(id) {
      this.$router.push({
        path: '/horizontalVideo',
        query: {
          id: id,
          via: 3,
          sectionId: this.section.videoInfo[0].watch.isFreeWatch ? this.section.sectionID : '',
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.warp {
  color: #333333;
  background: #ffffff;
  padding: 6px 0;
  .video_desc {
    max-height: 34px;
    font-size: 14px !important;
    display: -webkit-box;
    overflow: hidden;
    white-space: normal !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    color: #666666;
    -webkit-box-orient: vertical;
  }

  .videoTitle {
    font-size: 14px !important;
    display: -webkit-box;
    overflow: hidden;
    white-space: normal !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #333333;
  }

  .header {
    display: flex;
    justify-content: space-between;
    height: 24px;
    padding: 0 12px 0;
    .title {
      font-size: 16px;
      font-weight: bold;
      display: flex;
      align-items: center;
      color: #333333;
    }

    .more {
      font-size: 14px;
      color: #666666;
    }
  }

  .video_icon {
    position: absolute;
    top: 8px;
    left: 8px;
    font-size: 12px;

    .v_gold {
      width: 50px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      border-top-left-radius: 4px;
      background: linear-gradient(#fe765b, #ff455b);

      svg {
        padding-right: 4px;
      }
    }

    .v_vip {
      width: 50px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      border-top-left-radius: 4px;
      font-size: 14px;
      svg {
        width: 43px;
        height: 21px;
      }
    }
  }

  .small {
    font-size: 10px;

    .v_gold {
      width: 40px;
      height: 18px;
    }

    .v_vip {
      font-size: 10px;
      width: 40px;
      height: 18px;
    }
  }

  .content {
    padding: 8px 16px 0 16px;

    .one_big_img {
      overflow: hidden;
      // 兼容ios的圆角问题
      transform: translateZ(0px);
      .big_cover {
        height: 183px;
        position: relative;
        border-radius: 6px;
        overflow: hidden;
        /deep/ img {
          border-radius: 6px;
        }
      }

      p {
        font-size: 14px;
        line-height: 20px;
        padding: 8px 0;
      }
    }

    .four_small-img {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 6px;
      grid-column-gap: 6px;
      margin-top: 8px;

      .small_item {
        overflow: hidden;

        .small_img {
          height: 110px;
          position: relative;
          overflow: hidden;
          // 兼容ios的圆角问题
          border-radius: 6px;
          transform: translateZ(0px);
        }

        p {
          font-size: 12px;
          line-height: 20px;
          padding-top: 6px;
        }
      }
    }

    .video_info {
      width: 100%;
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      height: 30px;
      background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.6));
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      padding: 10px;
      color: #dcdee0;
      font-weight: 500;
      // 兼容ios的圆角问题
      transform: translateZ(0px);
      .watch {
        > svg {
          padding-right: 3px;
        }
      }
    }
  }
}
</style>
