var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warp"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.section.sectionName || ''))]),_c('router-link',{staticClass:"more",attrs:{"to":{
        path: '/movies/more/new',
        query: {
          title: _vm.section.sectionName || '',
          id: _vm.section.sectionID || '',
        },
      }}},[_vm._v(" 更多 ")])],1),_c('div',{staticClass:"content"},[_c('div',{staticClass:"one_big_img",on:{"click":function($event){return _vm.goPlay(_vm.section.videoInfo[0].id)}}},[_c('div',{staticClass:"big_cover"},[_c('ImgDecypt',{attrs:{"src":_vm.section.videoInfo[0].cover}}),_c('TipIcon',{attrs:{"videoInfo":_vm.section.videoInfo[0]}}),_c('div',{staticClass:"video_info"},[_c('div',{staticClass:"watch"},[_c('svg-icon',{attrs:{"icon-class":"tip_play"}}),_c('span',[_vm._v(_vm._s(_vm._f("watchCount")(_vm.section.videoInfo[0].playCount)))])],1),_c('div',{staticClass:"time"},[_c('span',[_vm._v(_vm._s(_vm._f("videotime")(_vm.section.videoInfo[0].playTime)))])])])],1),_c('p',{staticClass:"video_desc ellipsis"},[_vm._v(_vm._s(_vm.section.videoInfo[0].title))])]),_c('div',{staticClass:"four_small-img"},_vm._l((_vm.section.videoInfo.slice(1)),function(videoInfo){return _c('div',{key:videoInfo.id,staticClass:"small_item",on:{"click":function($event){return _vm.goPlay(videoInfo.id)}}},[_c('div',{staticClass:"small_img"},[_c('ImgDecypt',{attrs:{"src":videoInfo.cover}}),_c('TipIcon',{attrs:{"videoInfo":videoInfo}}),_c('div',{staticClass:"video_info"},[_c('div',{staticClass:"watch"},[_c('svg-icon',{attrs:{"icon-class":"tip_play"}}),_c('span',[_vm._v(_vm._s(_vm._f("watchCount")(videoInfo.playCount))+"次")])],1),_c('div',{staticClass:"time"},[_c('span',[_vm._v(_vm._s(_vm._f("videotime")(videoInfo.playTime)))])])])],1),_c('p',{staticClass:"videoTitle ellipsis"},[_vm._v(_vm._s(videoInfo.title))])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }