<template>
  <div class="varietShow_warp">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isNoData="isNoData"
      :error="error"
      :showOricilegeInfo="true"
      :is-higeht-max="true"
      class-name="varietyShowName"
    >
      <div v-for="section in list" :key="section.id">
        <OneBigFoursmall class="one" :section="section" />
        <div v-if="adList.length > 0" class="adImg">
          <ImgDecypt :itemData="getAdUrl(adList)" />
        </div>
      </div>
    </PullRefresh>
  </div>
</template>
<script>
import OneBigFoursmall from '@/views/movies/widget/oneBigFoursmall';
import { AdType, getAdItem, getMarquee, getModuleId, MarqueeType } from '@/utils/getConfig';
import { queryModulsList } from '@/api/movies';
import PullRefresh from '@/components/PullRefresh';
import ImgDecypt from '@/components/ImgDecypt';

export default {
  components: { PullRefresh, OneBigFoursmall, ImgDecypt },
  data() {
    return {
      pageSize: 5, // 每个专题下视频数量
      sectionPage: 1, // 专题页码
      sectionSize: '5', // 专题数量
      list: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      adList: [],
      cacheIndex: 'column_index_insert_banner',
    };
  },
  async created() {
    await this.getAd();
    // this.advList = getAdItem(AdType.MOVIES_DOMESTIC);
    // this.marqueeText = getMarquee(MarqueeType.movie);
    await this.isGetModule();
    await this.getList();
  },
  methods: {
    //如果没有拿到ID，再去请求拿一遍
    async isGetModule() {
      if (getModuleId('综艺') == null) {
        await this.$store.dispatch('movies/setMoudles');
      }
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.sectionPage++;
      }
      this.getList();
    },
    //获取列表
    async getList(type) {
      let req = {
        id: getModuleId('综艺'),
        data: {
          pageSize: String(this.pageSize),
          sectionSize: String(this.sectionSize),
          sectionPage: String(this.sectionPage),
        },
      };
      try {
        let res = await this.$Api(queryModulsList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.sections || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.sectionPage === 1 && this.list.length === 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (e) {
        this.loading = false;
        this.error = true;
      }
    },
    onRefresh() {
      this.sectionPage = 1;
      this.refreshing = true;
      this.finished = false;
      this.getList('refresh');
    },
    async getAd() {
      if (this.$store.getters.adsColumnList.list.length > 0) {
        this.adList = this.$store.getters.adsColumnList.list;
      }
    },
    getAdUrl(adList) {
      let adIndex = sessionStorage.getItem(this.cacheIndex);
      if (!adIndex) {
        adIndex = 0;
      } else {
        adIndex = Number(adIndex);
      }
      let itemAd = adList[adIndex];
      if (adIndex + 1 > adList.length - 1) {
        adIndex = 0;
      } else {
        adIndex = adIndex + 1;
      }
      sessionStorage.setItem(this.cacheIndex, adIndex);
      return itemAd;
    },
  },
};
</script>
<style scoped lang="scss">
.varietShow_warp {
  height: 100%;

  .one {
    margin-top: 10px;
  }
}

.adImg {
  height: calc(540px / 343 * 86 - 0.374rem - 0.374rem);
  padding: 0.35rem 0.374rem 0 0.374rem;
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
  /deep/ img,
  .vanImage {
    border-radius: 6px;
  }
  /deep/ .img_Decypt {
    border-radius: 6px;
    overflow: hidden;
  }
}
</style>
